import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import user from "./user.module";
import call from "./call.module";
import dropdown from "./dropdown.module";
import message from "./message.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import snackbar from "./snackbar.module";
import twilio from "./twilio.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    user,
    call,
    dropdown,
    message,
    snackbar,
    twilio
  }
});
