import ApiService from "@/core/services/api.service";

// action types
export const GET_DROPDOWNS_LIST = "getDropdownsList";
export const CREATE_DROPDOWN = "createDropdown";
export const DELETE_DROPDOWN = "deleteDropdown";

// mutation types
export const SET_DROPDOWNS_LIST = "setDropdownsList";
export const SET_ERROR = "setError";

const state = {
  error: null,
  dropdowns: []
};

const getters = {};

const actions = {
  [GET_DROPDOWNS_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.query(`dropdown`).then(({ data }) => {
        context.commit(SET_DROPDOWNS_LIST, data.data.dropdowns);
        resolve(data.data);
      });
    });
  },
  [CREATE_DROPDOWN](context, payload) {
    return new Promise(resolve => {
      ApiService.post("dropdown", payload).then(({ data }) => {
        context.commit(CREATE_DROPDOWN, data.data.dropdown);
        resolve(data.data);
      });
    });
  },
  [DELETE_DROPDOWN](context, payload) {
    return new Promise(resolve => {
      ApiService.delete(`dropdown/${payload.id}`).then(({ data }) => {
        context.commit(DELETE_DROPDOWN, payload.id);
        resolve(data.data);
      });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.error = error;
  },
  [SET_DROPDOWNS_LIST](state, dropdowns) {
    state.dropdowns = dropdowns;
  },
  [CREATE_DROPDOWN](state, dropdown) {
    state.dropdowns.push(dropdown);
  },
  [DELETE_DROPDOWN](state, dropdownId) {
    const dropdownIndex = state.dropdowns.findIndex(d => d.id === dropdownId);
    if (dropdownIndex !== -1) {
      state.dropdowns.splice(dropdownIndex, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
