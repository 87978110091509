import ApiService from "@/core/services/api.service";

// action types
export const GET_USERS_LIST = "getUsersList";
export const GET_USER = "getUser";
export const CREATE_USER = "createUser";
export const UPDATE_USER = "updateUser";
export const UPDATE_USER_PASSWORD = "updateUserPassword";
export const DELETE_USER = "deleteUser";

// mutation types
export const SET_USERS_LIST = "setUsersList";
export const SET_ERROR = "setError";

const state = {
  error: null,
  users: [],
  user: null
};

const getters = {
  findUser: state => id => {
    return state.users.find(user => user.id === id);
  }
};

const actions = {
  [GET_USERS_LIST](context) {
    return new Promise(resolve => {
      ApiService.get("user").then(({ data }) => {
        context.commit(SET_USERS_LIST, data.data);
        resolve(data.data);
      });
    });
  },
  [GET_USER](context, payload) {
    return new Promise(resolve => {
      context.commit(GET_USER, {});
      ApiService.get("user", payload).then(({ data }) => {
        context.commit(GET_USER, data.data.user);
        resolve(data.data);
      });
    });
  },
  [CREATE_USER](context, payload) {
    return new Promise(resolve => {
      ApiService.post("user", payload).then(({ data }) => {
        context.commit(CREATE_USER, data.data.user);
        resolve(data.data);
      });
    });
  },
  [UPDATE_USER](context, payload) {
    return new Promise(resolve => {
      ApiService.update("user", payload.id, payload).then(({ data }) => {
        context.commit(UPDATE_USER, data.data.user);
        resolve(data.data);
      });
    });
  },
  [UPDATE_USER_PASSWORD](context, payload) {
    return new Promise(resolve => {
      ApiService.put(`user/${payload.id}/password`, payload).then(
        ({ data }) => {
          context.commit(UPDATE_USER_PASSWORD, data.data.user);
          resolve(data.data);
        }
      );
    });
  },
  [DELETE_USER](context, payload) {
    return new Promise(resolve => {
      ApiService.delete(`user/${payload}`).then(({ data }) => {
        context.commit(DELETE_USER, payload);
        resolve(data.data);
      });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.error = error;
  },
  [SET_USERS_LIST](state, users) {
    state.users = users;
  },
  [GET_USER](state, user) {
    state.user = user;
  },
  [CREATE_USER](state, user) {
    state.users.push(user);
  },
  [UPDATE_USER](state, user) {
    const userIndex = state.users.findIndex(u => u.id === user.id);
    if (userIndex !== -1) {
      state.users[userIndex] = user;
    } else {
      state.users.push(user);
    }
  },
  [DELETE_USER](state, userId) {
    const userIndex = state.users.findIndex(u => u.id === userId);
    if (userIndex !== -1) {
      state.users.splice(userIndex, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
