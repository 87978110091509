import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@/view/pages/calls/Dashboard.vue'),
        },
        {
          path: '/calls',
          name: 'calls',
          component: () => import('@/view/pages/calls/Calls.vue'),
        },
        {
          path: '/calls/create',
          name: 'call/create',
          component: () => import('@/view/pages/calls/EditCall.vue'),
        },
        {
          path: '/calls/edit/:id',
          name: 'call/edit',
          component: () => import('@/view/pages/calls/EditCall.vue'),
        },
        {
          path: '/users',
          name: 'users',
          component: () => import('@/view/pages/users/Users.vue'),
        },
        {
          path: '/users/create',
          name: 'user/create',
          component: () => import('@/view/pages/users/CreateUser.vue'),
        },
        {
          path: '/users/edit/:id',
          name: 'user/edit',
          component: () => import('@/view/pages/users/EditUser.vue'),
        },
        {
          path: '/messages',
          name: 'messages',
          component: () => import('@/view/pages/messages/Messages.vue'),
        },
        {
          path: '/messages/children/:id',
          name: 'message/children',
          component: () => import('@/view/pages/messages/Messages.vue'),
        },
        {
          path: '/messages/create',
          name: 'message/create',
          component: () => import('@/view/pages/messages/EditMessage.vue'),
        },
        {
          path: '/messages/edit/:id',
          name: 'message/edit',
          component: () => import('@/view/pages/messages/EditMessage.vue'),
        },
        {
          path: '/iFrameFileManager',
          name: 'iFrameFileManager',
          component: () => import('@/view/pages/iframe-file-manager/IFrameFileManager.vue'),
        },
      ],
    },
    {
      path: '/custom-error',
      name: 'error',
      component: () => import('@/view/pages/error/Error.vue'),
      children: [
        {
          path: 'error-1',
          name: 'error-1',
          component: () => import('@/view/pages/error/Error-1.vue'),
        },
        {
          path: 'error-2',
          name: 'error-2',
          component: () => import('@/view/pages/error/Error-2.vue'),
        },
        {
          path: 'error-3',
          name: 'error-3',
          component: () => import('@/view/pages/error/Error-3.vue'),
        },
        {
          path: 'error-4',
          name: 'error-4',
          component: () => import('@/view/pages/error/Error-4.vue'),
        },
        {
          path: 'error-5',
          name: 'error-5',
          component: () => import('@/view/pages/error/Error-5.vue'),
        },
        {
          path: 'error-6',
          name: 'error-6',
          component: () => import('@/view/pages/error/Error-6.vue'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/view/pages/auth/login_pages/Login-1'),
      children: [
        {
          name: 'login',
          path: '/login',
          component: () => import('@/view/pages/auth/login_pages/Login-1'),
        },
      ],
    },
    {
      path: '*',
      redirect: '/404',
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue'),
    },
  ],
});
