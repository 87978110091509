import ApiService from "@/core/services/api.service";

// action types
export const GET_TWILIO_TOKEN = "getTwilioToken";
export const GET_TWILIO_WORKER_TOKEN = "getTwilioWorkerToken";
export const UPDATE_TWILIO_RESERVATION = "updateTwilioReservation";
export const COMPLETE_TWILIO_TASK = "completeTwilioTask";
export const CREATE_TWILIO_TASK = "createTwilioTask";
export const COMPLETE_TWILIO_CALL = "completeTwilioCall";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  token: null,
  workerToken: null
};

const getters = {};

const actions = {
  [GET_TWILIO_TOKEN](context) {
    return new Promise(resolve => {
      ApiService.get("twilio/token").then(({ data }) => {
        context.commit(GET_TWILIO_TOKEN, data.data);
        resolve(data.data);
      });
    });
  },
  [GET_TWILIO_WORKER_TOKEN](context) {
    return new Promise(resolve => {
      ApiService.get("twilio/worker-token").then(({ data }) => {
        context.commit(GET_TWILIO_WORKER_TOKEN, data.data);
        resolve(data.data);
      });
    });
  },
  [UPDATE_TWILIO_RESERVATION](context, payload) {
    return new Promise(resolve => {
      ApiService.put(
        `twilio/reservation/${payload.reservationSid}`,
        payload
      ).then(({ data }) => {
        resolve(data.data);
      });
    });
  },
  [COMPLETE_TWILIO_TASK](context, payload) {
    return new Promise(resolve => {
      ApiService.get(`twilio/task/${payload.taskSid}/complete`).then(
        ({ data }) => {
          resolve(data.data);
        }
      );
    });
  },
  [CREATE_TWILIO_TASK](context, payload) {
    return new Promise(resolve => {
      ApiService.post(`twilio/task/create`, payload).then(({ data }) => {
        resolve(data.data);
      });
    });
  },
  [COMPLETE_TWILIO_CALL](context, payload) {
    return new Promise(resolve => {
      ApiService.put(`twilio/call/${payload.callSid}/complete`, {}).then(
        ({ data }) => {
          resolve(data.data);
        }
      );
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [GET_TWILIO_TOKEN](state, payload) {
    state.token = payload.token;
  },
  [GET_TWILIO_WORKER_TOKEN](state, payload) {
    state.workerToken = payload.token;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
