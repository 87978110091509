import ApiService from "@/core/services/api.service";

// action types
export const GET_MESSAGES_LIST = "getMessagesList";
export const GET_ALL_MESSAGES_LIST = "getAllMessagesList";
export const GET_MESSAGE = "getMessage";
export const CREATE_MESSAGE = "createMessage";
export const UPDATE_MESSAGE = "updateMessage";
export const DELETE_MESSAGE = "deleteMessage";

// mutation types
export const SET_MESSAGES_LIST = "setMessagesList";
export const SET_ALL_MESSAGES_LIST = "setAllMessagesList";
export const SET_MESSAGE = "setMessage";
export const SET_ERROR = "setError";

const state = {
  error: null,
  messages: [],
  allMessages: [],
  message: {}
};

const getters = {};

const actions = {
  [GET_MESSAGES_LIST](context, payload) {
    payload = payload ? payload : 0;
    return new Promise(resolve => {
      ApiService.get(`twilio/message/${payload}/children`).then(({ data }) => {
        context.commit(SET_MESSAGES_LIST, data.data);
        resolve(data.data);
      });
    });
  },
  [GET_ALL_MESSAGES_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.get(`twilio/message`).then(({ data }) => {
        context.commit(SET_ALL_MESSAGES_LIST, data.data);
        resolve(data.data);
      });
    });
  },
  [GET_MESSAGE](context, payload) {
    return new Promise(resolve => {
      context.commit(SET_MESSAGE, {});
      ApiService.get("twilio/message", payload).then(({ data }) => {
        context.commit(SET_MESSAGE, data.data.twilioMessage);
        resolve(data.data);
      });
    });
  },
  [CREATE_MESSAGE](context, payload) {
    return new Promise(resolve => {
      ApiService.post(
        `twilio/message/${payload.messageType}`,
        payload.data
      ).then(({ data }) => {
        context.commit(CREATE_MESSAGE, data.data.twilioMessage);
        resolve(data.data);
      });
    });
  },
  [UPDATE_MESSAGE](context, payload) {
    return new Promise(resolve => {
      ApiService.update(
        `twilio/message/${payload.messageType}`,
        payload.id,
        payload.data
      ).then(({ data }) => {
        context.commit(UPDATE_MESSAGE, data.data.twilioMessage);
        resolve(data.data);
      });
    });
  },
  [DELETE_MESSAGE](context, payload) {
    return new Promise(resolve => {
      ApiService.delete(`twilio/message/${payload}`).then(({ data }) => {
        context.commit(DELETE_MESSAGE, payload);
        resolve(data.data);
      });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.error = error;
  },
  [SET_MESSAGES_LIST](state, messages) {
    state.messages = messages;
  },
  [SET_ALL_MESSAGES_LIST](state, messages) {
    state.allMessages = messages;
  },
  [SET_MESSAGE](state, message) {
    state.message = message;
  },
  [CREATE_MESSAGE](state, message) {
    state.messages.push(message);
  },
  [UPDATE_MESSAGE](state, message) {
    const messageIndex = state.messages.findIndex(u => u.id === message.id);
    if (messageIndex !== -1) {
      state.messages[messageIndex] = message;
    } else {
      state.messages.push(message);
    }
  },
  [DELETE_MESSAGE](state, messageId) {
    const messageIndex = state.messages.findIndex(m => m.id === messageId);
    if (messageIndex !== -1) {
      state.messages.splice(messageIndex, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
