import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/core/services/store';
import ApiService from '@/core/services/api.service';
import { VERIFY_AUTH } from '@/core/services/store/auth.module';
import { RESET_LAYOUT_CONFIG } from '@/core/services/store/config.module';

Vue.config.productionTip = false;

// Global 3rd party plugins
import moment from 'moment';
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n';
import vuetify from '@/core/plugins/vuetify';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/highlight-js';
import '@/core/plugins/inline-svg';
import '@/core/plugins/apexcharts';
// import '@/core/plugins/file-manager';
import '@/core/plugins/metronic';
import '@/core/plugins/treeselect';
import '@mdi/font/css/materialdesignicons.css';

// API service init
ApiService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.filter('date', (value, format) => {
  if (value) {
    return moment(String(value)).format(format || 'DD-MM-YYYY hh:mm');
  }
});

Vue.filter('time', (value) => {
  if (value) {
    const mins = Math.floor(value / 60);
    const secs = ('0' + Math.floor(value % 60)).slice(-2);
    return `${mins}:${secs}`;
  }

  return `0:00`;
});

Vue.filter('language', (value) => {
  const languages = [
    {
      value: 'sq-AL',
      text: 'Shqip, Albanian',
      canSay: false,
    },
    {
      value: 'sr-RS',
      text: 'Serbian, Serbia',
      canSay: false,
    },
    {
      value: 'da-DK',
      text: 'Danish, Denmark',
      canSay: true,
    },
    {
      value: 'de-DE',
      text: 'German, Germany',
      canSay: true,
    },
    {
      value: 'en-US',
      text: 'English, United States',
      canSay: true,
    },
    {
      value: 'es-ES',
      text: 'Spanish, Spain',
      canSay: true,
    },
    {
      value: 'fi-FI',
      text: 'Finnish, Finland',
      canSay: true,
    },
    {
      value: 'fr-FR',
      text: 'French, France',
      canSay: true,
    },
    {
      value: 'it-IT',
      text: 'Italian, Italy',
      canSay: true,
    },
    {
      value: 'ja-JP',
      text: 'Japanese, Japan',
      canSay: true,
    },
    {
      value: 'ko-KR',
      text: 'Korean, Korea',
      canSay: true,
    },
    {
      value: 'nb-NO',
      text: 'Norwegian, Norway',
      canSay: true,
    },
    {
      value: 'nl-NL',
      text: 'Dutch, Netherlands',
      canSay: true,
    },
    {
      value: 'pl-PL',
      text: 'Polish, Poland',
      canSay: true,
    },
    {
      value: 'pt-BR',
      text: 'Portuguese, Brazil',
      canSay: true,
    },
    {
      value: 'pt-PT',
      text: 'Portuguese, Portugal',
      canSay: true,
    },
    {
      value: 'ru-RU',
      text: 'Russian, Russia',
      canSay: true,
    },
    {
      value: 'sv-SE',
      text: 'Swedish, Sweden',
      canSay: true,
    },
    {
      value: 'zh-CN',
      text: 'Chinese (Mandarin)',
      canSay: true,
    },
    {
      value: 'zh-HK',
      text: 'Chinese (Cantonese)',
      canSay: true,
    },
    {
      value: 'zh-TW',
      text: 'Chinese (Taiwanese Mandarin)',
      canSay: true,
    },
  ];

  if (value) {
    const index = languages.findIndex((x) => x.value == value);
    if (index !== -1) {
      return languages[index].text;
    }
  }

  return 'none';
});

new Vue({ router, store, i18n, vuetify, render: (h) => h(App) }).$mount('#app');
