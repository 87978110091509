import ApiService from "@/core/services/api.service";

// action types
export const GET_CALLS_LIST = "getCallsList";
export const GET_CALL = "getCall";
export const CREATE_CALL = "createCall";
export const UPDATE_CALL = "updateCall";
export const DOWNLOAD_CALLS = "downloadCalls";
export const GET_CALLS_COUNT_STATS = "getCallsCountStats";
export const GET_CALLS_DURATION_STATS = "getCallsDurationStats";

// mutation types
export const SET_CALLS_LIST = "setCallsList";
export const SET_CALLS_TOTAL = "setCallsTotal";
export const SET_CALLS_COUNT_STATS = "setCallsCountStats";
export const SET_CALLS_DURATION_STATS = "setCallsDurationStats";
export const SET_ERROR = "setError";
export const SET_CONNECTION = "setConnection";

const state = {
  error: null,
  calls: [],
  call: null,
  total: 0,
  countStats: [],
  durationStats: [],
  connection: null
};

const getters = {
  findCall: state => id => {
    return state.calls.find(call => call.id === id);
  }
};

const actions = {
  [GET_CALLS_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.query(
        `call?page=${payload.page}&perPage=${payload.perPage}`
      ).then(({ data }) => {
        context.commit(SET_CALLS_LIST, data.data.calls);
        context.commit(SET_CALLS_TOTAL, data.data.total);
        resolve(data.data);
      });
    });
  },
  [GET_CALL](context, payload) {
    return new Promise(resolve => {
      context.commit(GET_CALL, {});
      ApiService.get("call", payload).then(({ data }) => {
        context.commit(GET_CALL, data.data.call);
        resolve(data.data);
      });
    });
  },
  [CREATE_CALL](context, payload) {
    return new Promise(resolve => {
      ApiService.post("call", payload).then(({ data }) => {
        context.commit(CREATE_CALL, data.data.call);
        resolve(data.data);
      });
    });
  },
  [UPDATE_CALL](context, payload) {
    return new Promise(resolve => {
      ApiService.update("call", payload.id, payload).then(({ data }) => {
        context.commit(UPDATE_CALL, data.data.call);
        resolve(data.data);
      });
    });
  },
  [DOWNLOAD_CALLS](context, payload) {
    return new Promise(resolve => {
      ApiService.get(
        `call/download?fromDate=${payload.startDate}&toDate=${payload.endDate}`
      ).then(({ data }) => {
        resolve(data);
      });
    });
  },
  [GET_CALLS_COUNT_STATS](context, payload) {
    return new Promise(resolve => {
      ApiService.get(
        `call/stats/count?fromDate=${payload.fromDate}&toDate=${payload.toDate}`
      ).then(({ data }) => {
        context.commit(SET_CALLS_COUNT_STATS, data.data.stats);
        resolve(data.data);
      });
    });
  },
  [GET_CALLS_DURATION_STATS](context, payload) {
    return new Promise(resolve => {
      ApiService.get(
        `call/stats/duration?fromDate=${payload.fromDate}&toDate=${payload.toDate}`
      ).then(({ data }) => {
        context.commit(SET_CALLS_DURATION_STATS, data.data.stats);
        resolve(data.data);
      });
    });
  },
  [SET_CONNECTION](state, payload) {
    state.commit(SET_CONNECTION, payload);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.error = error;
  },
  [SET_CALLS_LIST](state, calls) {
    state.calls = calls;
  },
  [GET_CALL](state, call) {
    state.call = call;
  },
  [CREATE_CALL](state, call) {
    state.calls.push(call);
  },
  [UPDATE_CALL](state, call) {
    const callIndex = state.calls.findIndex(u => u.id === call.id);
    if (callIndex !== -1) {
      state.calls[callIndex] = call;
    } else {
      state.calls.push(call);
    }
  },
  [SET_CALLS_TOTAL](state, total) {
    state.total = total;
  },
  [SET_CALLS_COUNT_STATS](state, stats) {
    state.countStats = stats;
  },
  [SET_CALLS_DURATION_STATS](state, stats) {
    state.durationStats = stats;
  },
  [SET_CONNECTION](state, payload) {
    state.connection = payload;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
