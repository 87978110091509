// action types
export const SHOW_SNACKBAR = "showSnackbar";

// mutation types
export const SET_SNACKBAR_DATA = "setSnackbar";

const state = {
  message: "",
  color: ""
};

const getters = {};

const actions = {
  [SHOW_SNACKBAR](context, payload) {
    context.commit(SET_SNACKBAR_DATA, payload);
  }
};

const mutations = {
  [SET_SNACKBAR_DATA](state, payload) {
    state.message = payload.message;
    state.color = payload.color;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
